import logo from './logo.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import './App.css';
import { Header } from './components/Header';
import "./css/main.min.css";
import "./css/page.min.css";
import "./css/scoller.min.css";
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import { pushRotate as Menu } from 'react-burger-menu';
import { LandingPage } from './components/Landingpage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Contact } from './components/Contact';
import 'rsuite/dist/rsuite.min.css';
import { Footer } from './components/Footer';
import { Maintenance } from './components/Maintenance';
import { ContactCTA } from './components/ContactCTA';
import { NotFound } from './Pages/NotFound';
import { useTranslation } from "react-i18next";
import CTAMouseLeaveAlert from './components/CTAMouseLeaveAlert';
import { WebsiteLatenMaken } from './Pages/WebsiteLatenMaken';
import { WebsiteLatenMaken_BLOG } from './blogs/blog-Website-laten-maken';
import { PDFSigner } from './Pages/PDFSigner';
import { getSubdomain } from './src/utils/getSubdomain';
import { Testje } from './testje';
import { Cases } from './Pages/Cases';
import { WebsiteTemplates } from './Pages/WebsiteTemplates';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import { Case } from './Pages/Case';
import { InitGA } from './components/GAInitialize';
import TagManager from 'react-gtm-module'
import { CookieBanner } from './components/CookieBanner';

function App() {
  const { t } = useTranslation('app');
  const subdomain = getSubdomain();
  const [domain, setDomain] = useState('');
  const [maintenance, setMaintenance] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [trackingId, setTrackingId] = useState();
  useEffect(() => {
    InitGA();

    
    AOS.init({ 
      duration: 700,
      easing: 'ease-in-out', // Easing function
      once: true, // Animation should happen only once
      offset: 50, 
    }); // Initialize AOS with default options
  }, []);
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Outfit:100,200,300,400,500,600,700,800,900,1000']
      }
    });
 
    const hostname = window.location.hostname;
    setDomain(hostname.endsWith('.nl') ? 'nl' : 'com');

    // const hostname = window.location.hostname;
    // setDomain(hostname.endsWith('.nl') ? 'nl' : 'com');
    
 

    const button = document.getElementById("toggleMenu");
    if (button) {
      button.addEventListener('click', toggleMenu);
    }

    // Cleanup event listener
    return () => {
      if (button) {
        button.removeEventListener('click', toggleMenu);
      }
    };


  }, []);

  useEffect(() => {
    if(domain)
    {
      console.log("DOMAIN: " + domain);
      // console.log(domain == "nl" ? 'GTM-56B9V87' : 'GTM-P6ZTJXJ');
      setTrackingId(domain == "nl" ? 'GTM-56B9V87' : 'GTM-P6ZTJXJ'); // Replace with actual GTM IDs
    }
  }, [domain])

  useEffect(() => {
    if (trackingId) { // Only run this effect if trackingId is defined
    // const script = document.createElement('script');
    // script.src = `https://www.googletagmanager.com/gtm.js?id=${trackingId}`;
    // script.async = true;
    // document.body.appendChild(script);
    TagManager.initialize({gtmId: trackingId});
    }
  }, [trackingId]);
  //subdomain apps
  const subdomainApp = subdomain === "ondertekenen" || subdomain === "sign";
 
  const getLastPathSegment = () => {
      const path = window.location.pathname;
      const segments = path.split('/');
      return segments[segments.length - 2] || segments[segments.length - 1]; // Handles trailing slash
  };
  return (
    <>
    
      <CookieBanner />
      {getLastPathSegment() != "" ? <>
        <Helmet>
          {/* <title key="title"> {subdomain.charAt(0).toUpperCase() + subdomain.slice(1)} | ZWL Software</title> */}
          <title key="title"> {  getLastPathSegment().replace(/-/g, ' ').substring(0, 1).toUpperCase() + getLastPathSegment().replace(/-/g, ' ').substring(1, getLastPathSegment().length) }  | ZWL Software </title>
        </Helmet>
      </> : (
          <Helmet>
            {domain === 'nl'
              ? [
                <title key="title"> De software agency voor digitale oplossingen. | ZWL Software</title>,
                <meta
                  key="description"
                  name="description"
                  content="Op zoek naar de perfecte website en wil je de online zichtbaarheid van je bedrijf verbeteren? Bij ZWL Software, jouw expert in webdesign, maken we op maat gemaakte websites die passen bij jouw bedrijf. "
                />,
              ]
              : [
                <title> The software agency for digital solutions. | ZWL Software</title>,
                <meta name="description" content="Looking for the perfect website while boosting your business's online visibility? At ZWL Software, your expert in web design, we create custom websites that fit your business. " />,

              ]}
          </Helmet>
      )}
      {maintenance === false ? (
        <main id="page-wrap">


          {subdomainApp ? null : (
            <Header />
          )}

          <div className='pt-10 flex flex-col justify-between'>
            
              <Router>
                <Routes>
                  {subdomain ? (
                    <>
                      <Route path="/" element={<PDFSigner />} />
                    </>
                  ) : (<>
                    <Route path={t('header.link-home')} element={<LandingPage />} ></Route>
                    <Route path={t('header.link-contact')} element={<Contact />} ></Route>
                    <Route path={t('header.link-create-website')} element={<WebsiteLatenMaken />} ></Route>
                    <Route path={t('blogs.link-create-website')} element={<WebsiteLatenMaken_BLOG />} ></Route>
                    <Route path={t('header.link-cases')} element={<Cases />} ></Route>
                    <Route path={t('header.link-website-templates')} element={<WebsiteTemplates />} ></Route>
                    <Route path={t('header.link-privacy')} element={<PrivacyPolicy />} ></Route>
                    <Route path={"/cases/case/*"} element={<Case />} ></Route>
                    <Route path="test" element={<Testje />} ></Route>

                    <Route path="*" element={<NotFound />} />
                  </>
                  )}
                </Routes>
              </Router>
    
            <ContactCTA />
            <Footer /> </div>
        </main>
      ) : <Maintenance />}

      {/* Rest of your application */}
    </>
  );
}



export default App;
