import { FaArrowRight, FaPhoneVolume } from "react-icons/fa";
import { Button, Form, Input, SelectPicker } from "rsuite";
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import axios from "axios";
import { BsArrowRight } from "react-icons/bs";
import ConfettiExplosion from "react-confetti-explosion";
export const Contact = () => {
    const { t } = useTranslation('contact');
    const [hasSubmitContent, setHasSubmitContent] = useState(false);
    const [confettiList, setConfettiList] = useState([]);

    const handleConfetti = () => {
        const newConfetti = { id: Date.now() };
        setHasSubmitContent(true);
        
        // Add a new confetti explosion to the list
        setConfettiList((prevList) => [...prevList, newConfetti]);
    
        // Remove the confetti explosion after 10 seconds
        setTimeout(() => {
            setConfettiList((prevList) =>
            prevList.filter((confetti) => confetti.id !== newConfetti.id)
            );

        }, 2500); 

    };
    
    // Extract options from translation
    const data = [

        { label: t('options.app-design'), value: t('options.app-design') },
        { label: t('options.website-development'), value: t('options.website-development') },
        { label: t('options.webshop-development'), value: t('options.webshop-development') },
        { label: t('options.WordPress'), value: t('options.WordPress') },
        { label: t('options.Shopify'), value: t('options.Shopify') },
        { label: t('options.seo-service'), value: t('options.seo-service') },
        { label: t('options.hosting'), value: t('options.hosting') },
        { label: t('options.domain'), value: t('options.domain') },
        { label: t('options.software-development'), value: t('options.software-development') },
        { label: t('options.other'), value: t('options.other') }
    ];
   
    const [submittingData, SetSubmittingData] = useState(false);
// Handle form input changes
const handleChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
};
const [formData, setFormData] = useState({
    name: '',
    email: '',
    msg: '',
    interest: ''
});

const handleQuoteSubmission = async () => {
    console.log("sb");
    console.log(formData);
    setHasSubmitContent(false);
    SetSubmittingData(true);

    try {
        const response = await axios.post('https://api.zwlsoftware.nl/zwlsoftware/index.php', {
            func: "new_contact",
            name: formData.name,
            email: formData.email,
            msg: formData.msg,
            interest: formData.interest,
        },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        
        console.log(response.data); // Handle successful response
        setFormData({ func: 'new_contact', name: '', email: '', msg: '' }); // Reset form
        handleConfetti();

    } catch (error) {
        console.error(error); // Handle error
    } finally {
        // Do something when done?
    }

    setTimeout(() => {
        SetSubmittingData(false);
    }, 5000);
}

return (
    <div>
          {hasSubmitContent && (
                <div className="absolute w-screen h-screen">
                    <div className="float-left">{confettiList.map((confetti) => (
          <ConfettiExplosion key={confetti.id} />
        ))}</div>
                    <div className="float-right">{confettiList.map((confetti) => (
          <ConfettiExplosion key={confetti.id} />
        ))}</div>
                </div>
            )}
        <div className="mt-20 mb-40 flex justify-center items-center">
            <div className="md:w-1/2 w-[90%] form">
                <h1 className="title text-black mb-4 md:text-start text-center !font-[600]" data-aos="fade-down" data-aos-duration={500} data-aos-delay={100}>{t('contact-title')} </h1>

                <h5 className="text-black ml-1 mb-10  md:text-start text-center" data-aos="fade-down" data-aos-duration={500} data-aos-delay={400}>{t('contact-sub')} </h5>

                <Form onSubmit={(e) => { handleQuoteSubmission();  }}>
                    <p>{t('interest-in')}</p>
                    <Select
                        className="w-full selector"
                        options={data}
                        searchable={false}
                        placeholder={t('choose-an-option')}
                        onChange={(e) => handleChange(e.value, "interest")}
                    />

                    <div className="md:flex mt-5 mb-4 gap-3 w-full">
                        <Input
                            type="text"
                            id="full-name"
                            name="full-name"
                            autoComplete="name"
                            className="input rounded w-full mb-5 md:mb-0"
                            placeholder={t('form.name')}
                            value={formData.name}
                            onChange={(e) => handleChange(e, 'name')}
                        />
                        <Input
                            name="Emailadres"
                            autoComplete="email"
                            className="input rounded w-full"
                            placeholder={t('form.email')}
                            value={formData.email}
                            onChange={(e) => handleChange(e, 'email')}
                        />
                    </div>
                    <div className="md:flex mt-5 mb-4 gap-3 w-full">
                        <textarea
                            rows={5}
                            maxLength={2089}
                            name="Message"
                            className="input rounded w-full"
                            placeholder={t('form.message')}
                            value={formData.msg}
                            onChange={(e) => handleChange(e.target.value, 'msg')}
                        />
                    </div>

                    <div className="md:flex items-center gap-2 md:justify-between">
                        <Button type="submit" className="contact-btn-1 md:w-1/3 !p-4 w-full bg-secondary text-white">
                            <div className="bg-secondary flex items-center gap-1">
                                {t('form.submit')} <BsArrowRight />
                            </div>
                        </Button>
                        <p className="text-gray-800 md:w-1/4 md:text-end w-full text-sm mt-10 hidden">
                            {t('phone-warn')}
                        </p>
                    </div>
                </Form>

                <hr />

                <span className="block text-center my-3 flex justify-start items-center mt-10">
                    {t('phone-available')}
                </span>
                <span className="text-gray-500">
                    {t('phone-times1')}<br />
                    {t('phone-times2')}
                </span>
                <br />
                <br />
                {t('phone-warn')}
            </div>
        </div>
    </div>
)};