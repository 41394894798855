import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom';

export const CasesScroller = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigator = useNavigate(); 
  const openCase = ({ caseName }) => {
    // Remove common domain extensions (.nl, .com, etc.)
      const cleanName = caseName.replace(/\.(nl|com|org|net|edu)$/i, '');
      navigator("/cases/case/" + cleanName+"/");
  };

  const cases = [
    { id: 1, image: '/cases/1.jpeg', text: 'modoskynn.nl', href: "https://modoskynn.nl" },
    { id: 2, image: '/cases/2.png', text: 'initialsounds.com', href: "https://initialsounds.com" },
    { id: 3, image: '/cases/3.png', text: 'zwlsoftware' , href: "https://zwlsoftware.nl"},
    // Add more cases as needed
  ];

  return (
    <div className="cases-scroller flex md:flex-nowrap flex-wrap md:flex-row flex-col items-center pt-10 gap-10">
      {cases.map((c, index) => (
        
        <div
          className={`   animated ${hoveredIndex === index ? 'hovered' : ''}` }
          key={c.id}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
            <div className="case" 
            data-aos-duration={500} data-aos-delay={index * 300} data-aos="fade-in"
            >
                <div className={`${index  == 1 ? "scale-105" : "" } `}>
                  <a href="" className='w-full' onClick={() => openCase({caseName: c.text})}>
                    <img src={c.image} className='shadow-lg h-full' alt={c.text} />
                  </a>
                </div>
            </div>
        </div>

      ))}
    </div>
  );
}
