import { FaCheck, FaCode } from "react-icons/fa";
import PulsingSquares from "../components/PulseSquares";
import { useTranslation } from "react-i18next";
import { Button } from "rsuite";
import { FaArrowRightLong, FaPaintbrush } from "react-icons/fa6";
import { Features } from "../components/Features";
import { CTAContact } from "../components/CTAContact";
import { MdOutlineScreenSearchDesktop, MdScreenSearchDesktop } from "react-icons/md";
import { BiPencil } from "react-icons/bi";
import { BsArrowRight, BsBrush } from "react-icons/bs";
import { FAQ } from "../components/FAQ";
import CTAMouseLeaveAlert from "../components/CTAMouseLeaveAlert";
import { WebFeatures } from "../components/WebFeatures";
import { PageTitlePannel } from "../components/PageTitlePannel";
import { FeaturePannel } from "../components/FeaturePannel";
import { WebPackages } from "../components/WebPackages";
import { TextBanner } from "../components/TextBanner";
import { Reviews } from "../components/Reviews";
import { QuotePannel } from "../components/QuotePannel";
import { ContactPannel } from "../components/ContactPannel";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { scroller } from 'react-scroll';
export const WebsiteLatenMaken = () => {
    const { t } = useTranslation('createWebsite');
    const location = useLocation();
    const navigate = useNavigate(); 
    useEffect(() => {
        if (location.hash === '#quote-request') {
          scroller.scrollTo('quote-request', {
            smooth: true,
            duration: 500,
            offset: -150, // Adjust offset if needed
          });
        }
      }, [location.hash]);
    const benefitsContent = [
        {
            title: t('benefitsContent.t1')
        },
        {
            title: t('benefitsContent.t2')
        },
        {
            title: t('benefitsContent.t3')
        },
        {
            title: t('benefitsContent.t4')
        },
    ]

    const goToContact = () =>{
        
        navigate('/contact');
    }
    return (
        <>
        <div className="content ">
            <CTAMouseLeaveAlert />
            {/* <div className="w-full mt-10 !mb-80">
                <div className="content md:py-20 ">
                    <div className="md:w-1/2 w-full ">
                        <PageTitlePannel />
                    </div>
                </div>

            </div> */}
            <div className=" md:mt-20 md:px-0 px-6  mx-3  m-auto">
                <div className="text-start">
                    <h1 className="text-black md:mb-5 mb-3 title !font-[600]" data-aos-duration={500} data-aos="fade-left">{t('title')} </h1>
                    <div className="m-0 w-[90%]  " data-aos-delay={100} data-aos="fade-left" >
                        <p className="pb-6">{t('desc')}</p>
                    </div>
                    <div className="flex flex-col items-center md:items-start gap-4 my-3 md:mr-10">
                        {benefitsContent.map((data, index) => (
                            <span className="flex flex-row items-center justify-between w-full gap-3" data-aos-delay={250 + index * 300} data-aos="fade-in"> <FaCheck color="green" /> <span className="w-full text-start"> {data.title} </span> </span>
                        ))}
                    </div>

                    <div className="flex gap-4 md:flex-row flex-col md:justify-between items-center md:flex-row flex-col  ">
                        <div className="md:block hidden w-full">

                        </div>
                        <div className="w-full " data-aos="fade-in" data-aos-duration="500" data-aos-delay={1200}>
                            <div className="flex md:flex-row flex-col gap-8 items-center md:justify-end justify-start py-4">
                                <Button onClick={() => goToContact()} className="btn-main md:w-[30%] w-full !p-4 w-full" id="component01_contact"><span className="text-white"> {t('cta-contact')} </span> </Button> 
                                <a href={t('cta-cases-link')} className="text-gray-500 hover:text-black group flex items-center gap-2 justify-start" id="component02_cases">
                                    {t('cta-cases')}
                                    <span className="inline-block transform transition-transform duration-300 group-hover:translate-x-2 mt-[2px]">
                                        <BsArrowRight />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" py-20">
                <FeaturePannel />
            </div>

            
            <div className=" md:pt-20 pb-5">
                <WebPackages />

            </div>
        
            <div className="md:px-0 px-6 pt-10 pb-20">
                <FAQ />
            </div>

        </div>
        <TextBanner  title={t('title-card')} WebCards={true} />
        <div className="text-banner"></div>
        <div className="w-full pt-10 pb-5">
            <Reviews  cta_={true}/>
        </div>
        <div className="w-full" id="quote-request">
            <QuotePannel  />
        </div>
        <ContactPannel />
    </>


    );
}